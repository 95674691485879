import React, { useState } from 'react';
import {
  Flex,
  Grid,
  Link,
  Table,
  Tbody,
  Text,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import DashboardTableRow from 'components/Tables/DashboardTableRow';
import { dashboardTableData } from 'variables/general';
import WithdrawForm from 'components/Model/WithdrawForm';
import AddFundForm from 'components/Model/AddFundForm'; // Import your AddFundForm component

export default function Dashboard() {
  const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
  const [isAddFundOpen, setIsAddFundOpen] = useState(false);

  const balance = "₹ 1,250.00"; // Example balance, you can replace it with dynamic data

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      p={{ base: '4', md: '8' }}
      pt={{ base: '100px', md: '100px', sm: '120px' }} // Added padding-top to avoid sticking to header
    >
      {/* Balance Display */}
      <Flex
        justify="space-between"
        width="100%"
        mb="24px"
        px={{ base: '4', md: '8' }}
      >
        <Box />
        <Box>
          <Text fontSize={{ base: 'md', md: 'lg' }} color="#fff" fontWeight="bold">
            Balance: {balance}
          </Text>
        </Box>
      </Flex>

      {/* Buttons Section */}
      <Flex
        mb="24px"
        justifyContent={{ base: 'center', md: 'space-between' }}
        gap={{ base: '12px', md: '16px' }}
        flexDirection={{ base: 'column', md: 'row' }} // Stack buttons on mobile
        width={{ base: '100%', md: 'auto' }} // Full-width buttons on mobile
        alignItems="center"
      >
        <Button width={{ base: '100%', md: 'auto' }}>
          <Link as={RouterLink} to="manageFund">
            Manage Fund
          </Link>
        </Button>

        <Button
          onClick={() => setIsWithdrawOpen(true)}
          width={{ base: '100%', md: 'auto' }}
        >
          Withdraw
        </Button>

        <Button
          onClick={() => setIsAddFundOpen(true)}
          width={{ base: '100%', md: 'auto' }}
        >
          Deposit
        </Button>
      </Flex>

      {/* Card Section */}
      <Card p="16px" overflowX={{ sm: 'scroll', xl: 'hidden' }} width={{ base: 'full', md: '1200px' }}>
        <CardHeader p="12px 0px 28px 0px">
          <Flex direction="column">
            <Text fontSize="lg" color="#fff" fontWeight="bold" pb="8px">
              Bids
            </Text>
          </Flex>
        </CardHeader>
        <Table variant="simple" color="#fff">
          <Tbody>
            {dashboardTableData.map((row, index, arr) => (
              <DashboardTableRow
                key={index} // Ensure a unique key for each row
                name={row.name}
                logo={row.logo}
                starttime={row.starttime}
                endtime={row.endtime}
                status={row.status}
                lastItem={index === arr.length - 1}
              />
            ))}
          </Tbody>
        </Table>
      </Card>

      {/* Withdraw Modal */}
      <Modal isOpen={isWithdrawOpen} onClose={() => setIsWithdrawOpen(false)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Withdraw Funds</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WithdrawForm />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Add Fund Modal */}
      <Modal isOpen={isAddFundOpen} onClose={() => setIsAddFundOpen(false)} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Funds</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AddFundForm />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
