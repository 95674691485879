import {
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  Button,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

function DashboardTableRow(props) {
  const { logo, name, starttime, endtime, status, lastItem } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr>
      <Td
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Icon as={logo} h={"24px"} w={"24px"} me='18px' />
          <Text fontSize='m' color='#fff' fontWeight='normal' minWidth='100%'>
            {name}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          Open Bid : {starttime}
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          Close Bid : {endtime}
        </Text>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Flex direction='column' align='center' justify='center'> 
        <Button 
  variant='no-hover' 
  bg={status === "Close" ? "grey" : "#071ef7"} 
  color={"#fff"}
>
  {status === "Play Game" ? (
    <RouterLink to="games" style={{ textDecoration: 'none', color: '#fff' }}>
      {status}
    </RouterLink>
  ) : (
    status
  )}
</Button>
        </Flex>
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;
