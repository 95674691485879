import React, { useState } from "react";
import { Button, Input, FormControl, FormLabel, Flex, Image, Text, Link } from "@chakra-ui/react";
import qrScannerImage from "assets/img/scanner.jpeg";

function AddFundForm() {
  const [showQRScanner, setShowQRScanner] = useState(false);

  const handleAddFundClick = () => {
    setShowQRScanner(true); // Show QR Scanner image when button is clicked
  };

  return (
    <Flex direction="column" gap="60px" mb={"10px"}>
      <FormControl>
        <FormLabel>Amount</FormLabel>
        <Input placeholder="Enter amount" type="number" />
      </FormControl>
      <Button colorScheme="blue" marginBottom={'40px'} onClick={handleAddFundClick}>
        Add Fund
      </Button>

      {/* Show QR Scanner image when 'showQRScanner' is true */}
      {showQRScanner && (
        <Flex direction="column" alignItems="center" mt={5}>
          {/* Display the QR image in original size */}
          <Image src={qrScannerImage} alt="QR Scanner" />

          {/* Add text and clickable phone number */}
          <Text color="yellow.500" mt={5} textAlign="center">
            For Add Funds Related Query Call Or WhatsApp 
            <Link href="https://wa.me/917014565600" color="blue.400" isExternal ml={1}>
              +91 7014565600
            </Link>
          </Text>

          <Text color="gray.700" mt={3} textAlign="center">
            Deposit by QR code scan and send payment screenshot to this number via WhatsApp.
          </Text>
        </Flex>
      )}
    </Flex>
  );
}

export default AddFundForm;
