import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Flex, Grid, Text, Link } from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import IconBox from "components/Icons/IconBox";
import { BillIcon, TreeLeafIcon, DoublePattiIcon, MotorIcon, TriangleIcon } from "components/Icons/Icons";

function Games() {
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }} mx='auto'>
      <Grid templateColumns={{ sm: "1fr", lg: "50% 50%" }} gap='30px'>
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr 1fr",
            }}
            gap='30px'>
            <Card w={{ base: "100%", md: "350px" }} p='6' boxShadow='lg'>
              <Flex direction='column' align='center' p='4'>
                <IconBox
                  bg='#22234B'
                  borderRadius='30px'
                  w='60px'
                  h='60px'
                  mb='3'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <BillIcon w='36px' h='36px' color='white' />
                </IconBox>
                <Text color='#fff' fontSize='lg' textAlign='center' whiteSpace='nowrap'>
                  <Link as={RouterLink} to="singal-ank">
                    Single Ank
                  </Link>
                </Text>
              </Flex>
            </Card>
            <Card w={{ base: "100%", md: "350px" }} p='6' boxShadow='lg'>
              <Flex direction='column' align='center' p='4'>
                <IconBox
                  bg='#22234B'
                  borderRadius='30px'
                  w='60px'
                  h='60px'
                  mb='3'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <TreeLeafIcon w='36px' h='36px' color='white' />
                </IconBox>
                <Text color='#fff' fontSize='lg' textAlign='center' whiteSpace='nowrap'>
                  <Link as={RouterLink} to="singal-patti">
                    Single Patti
                  </Link>
                </Text>
              </Flex>
            </Card>
            <Card w={{ base: "100%", md: "350px" }} p='6' boxShadow='lg'>
              <Flex direction='column' align='center' p='4'>
                <IconBox
                  bg='#22234B'
                  borderRadius='30px'
                  w='60px'
                  h='60px'
                  mb='3'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <DoublePattiIcon w='36px' h='36px' color='white' />
                </IconBox>
                <Text color='#fff' fontSize='lg' textAlign='center' whiteSpace='nowrap'>
                  <Link as={RouterLink} to="double-patti">
                    Double Patti
                  </Link>
                </Text>
              </Flex>
            </Card>
            <Card w={{ base: "100%", md: "350px" }} p='6' boxShadow='lg'>
              <Flex direction='column' align='center' p='4'>
                <IconBox
                  bg='#22234B'
                  borderRadius='30px'
                  w='60px'
                  h='60px'
                  mb='3'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <MotorIcon w='36px' h='36px' color='white' />
                </IconBox>
                <Text color='#fff' fontSize='lg' textAlign='center' whiteSpace='nowrap'>
                  <Link as={RouterLink} to="tripple-patti">
                    Tripple Patti
                  </Link>
                </Text>
              </Flex>
            </Card>
            <Card w={{ base: "100%", md: "350px" }} p='6' boxShadow='lg'>
              <Flex direction='column' align='center' p='4'>
                <IconBox
                  bg='#22234B'
                  borderRadius='30px'
                  w='60px'
                  h='60px'
                  mb='3'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <TriangleIcon w='36px' h='36px' color='white' />
                </IconBox>
                <Text color='#fff' fontSize='lg' textAlign='center' whiteSpace='nowrap'>
                  <Link as={RouterLink} to="half-sangam">
                    Half Sangam
                  </Link>
                </Text>
              </Flex>
            </Card>
            <Card w={{ base: "100%", md: "350px" }} p='6' boxShadow='lg'>
              <Flex direction='column' align='center' p='4'>
                <IconBox
                  bg='#22234B'
                  borderRadius='30px'
                  w='60px'
                  h='60px'
                  mb='3'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'>
                  <TriangleIcon w='36px' h='36px' color='white' />
                </IconBox>
                <Text color='#fff' fontSize='lg' textAlign='center' whiteSpace='nowrap'>
                  <Link as={RouterLink} to="full-sangam">
                    Full Sangam
                  </Link>
                </Text>
              </Flex>
            </Card>
            {/* You can add more cards with the same pattern here */}
          </Grid>
        </Box>
      </Grid>
    </Flex>
  );
}

export default Games;
