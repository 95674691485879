import { Flex, Text } from "@chakra-ui/react";

function GameRateRow(props) {
  const { rate } = props; // Props contain the rate to display

  return (
    <Flex
      mb="24px"
      alignItems="center"
      justifyContent="center" // Centers content horizontally
      direction="column"      // Stacks the title and rate vertically
    >
      <Text fontSize="xl" color="#fff" fontWeight="semibold" textAlign="center">
        {rate}
      </Text>
    </Flex>
    
  );
}

export default GameRateRow;
