import React from "react";
import { Table, Tbody, Thead, Th, Tr, Td, Text } from "@chakra-ui/react";

function FundHistory() {
  const fundHistoryData = [
    { date: "2023-09-01", amount: "₹ 500", status: "Success" },
    { date: "2023-08-20", amount: "₹ 300", status: "Pending" },
    { date: "2023-08-10", amount: "₹ 1000", status: "Failed" },
  ];

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" mb="20px">
        Your Fund Addition History
      </Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Amount</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {fundHistoryData.map((row, index) => (
            <Tr key={index}>
              <Td>{row.date}</Td>
              <Td>{row.amount}</Td>
              <Td>{row.status}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default FundHistory;
