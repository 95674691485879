import React, { useState } from 'react';
import { Box, Flex, Input, Select, Text, Grid, Button, RadioGroup, Radio, Stack, FormControl, FormLabel, color } from '@chakra-ui/react';

const BettingForm = () => {
  const [session, setSession] = useState('Open');


  return (
    <Box p={5} mt={40}>
      <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
        <FormLabel color="yellow.500">Select Date</FormLabel>
        <Input type="date" width="100%" color={"white"} />
      </FormControl>

      <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
        <FormLabel color="yellow.500">Choose Session</FormLabel>
        <RadioGroup onChange={setSession} value={session}>
          <Stack direction="row">
            <Radio value="Open" colorScheme="blue">
              <span style={{ color: "white" }}>Open</span>
            </Radio>
            <Radio value="Close" colorScheme="blue">
              <span style={{ color: "white" }}>Close</span>
            </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
        <FormLabel color="yellow.500">Close Digit</FormLabel>
        <Input type="text" placeholder="Close Digit" color="white" />
      </FormControl>
      <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
        <FormLabel color="yellow.500">Open Panna</FormLabel>
        <Input type="text" placeholder="Open Panna" color="white" />
      </FormControl>

      <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
        <FormLabel color="yellow.500">Bid Points</FormLabel>
        <Input type="number" placeholder="Bid Points" color="white" />
      </FormControl>

      <Flex justifyContent="space-between" mt={5}>
        <Button colorScheme="blue">Submit BID</Button>
      </Flex>
    </Box>
  );
};

export default BettingForm;
