import React from "react";

// Chakra imports
import { Box, Button, Flex, Grid, Icon, Spacer, Text } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ManageFundRow from "components/Tables/ManageFundRow";

// Data
import {
    ManageFundData,
} from "variables/general";

function ManageFund() {

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }} mx='auto'>
      <Grid templateColumns={{ sm: "1fr", lg: "90% 50%" }}>
        <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
          <Flex direction='column'>
            <CardBody>
              <Flex direction='column' w='100%'>
                {ManageFundData.map((row) => {
                  return (
                    <ManageFundRow
                      name={row.name}
                      discription={row.discription}
                      type={row.type}
                    />
                  );
                })}
              </Flex>
            </CardBody>
          </Flex>
        </Card>
      </Grid>
    </Flex>
  );
}

export default ManageFund;
