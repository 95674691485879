import React from "react";

import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode,
} from "@chakra-ui/react";

import GradientBorder from "components/GradientBorder/GradientBorder";

function SignIn() {
  const titleColor = "white";
  const textColor = "gray.400";

  return (
    <Flex position="relative" minH="100vh" w="100%" justifyContent="center">
      <Flex
        minH="100vh"
        w="100%" // Full width of the screen
        maxW="900px" // Set a max width to limit the form width
        mx="auto" // Center the form
        px={{ base: "20px", md: "50px" }} // Padding on the sides for mobile and desktop
        pt={{ sm: "100px", md: "0px" }}
        flexDirection="column"
      >
        <Flex
          alignItems="center"
          justifyContent="center" // Center the form content
          style={{ userSelect: "none" }}
          w="100%"
          px="50px"
        >
          <Flex
            direction="column"
            w="100%" // Ensure the form content takes full available width
            background="transparent"
            mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
            mb={{ base: "60px", lg: "95px" }}
          >
            <Heading
              color={titleColor}
              fontSize="32px"
              mb="10px"
              textAlign="center"
            >
              Nice to see you!
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
              textAlign="center"
            >
              Enter your email and password to sign in
            </Text>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                color="white"
              >
                Mobile
              </FormLabel>
              <GradientBorder mb="24px" w="100%" borderRadius="20px">
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  size="lg"
                  w="100%" // Ensure the input takes full width
                  h="46px"
                  placeholder="Your Mobile Number"
                />
              </GradientBorder>
            </FormControl>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="normal"
                color="white"
              >
                Password
              </FormLabel>
              <GradientBorder mb="24px" w="100%" borderRadius="20px">
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  size="lg"
                  w="100%"
                  type="password"
                  placeholder="Your password"
                />
              </GradientBorder>
              <Text textAlign="right" mb="24px" color={textColor} fontWeight="bold" fontSize="sm">
              <Link
                  as={RouterLink} // Use RouterLink for internal navigation
                  color={titleColor}
                  ms="5px"
                  fontWeight="bold"
                  to="/auth/forgot-password" // Route to the SignIn page
                >
                  Forgot Password?
                </Link>
              </Text>


            </FormControl>
            <FormControl display="flex" alignItems="center">
              <DarkMode>
                <Switch id="remember-login" colorScheme="brand" me="10px" />
              </DarkMode>
              <FormLabel
                htmlFor="remember-login"
                mb="0"
                ms="1"
                fontWeight="normal"
                color="white"
              >
                Remember me
              </FormLabel>
            </FormControl>
            <Button
              variant="brand"
              fontSize="10px"
              type="submit"
              w="100%" // Full width for the button
              h="45"
              mb="20px"
              mt="20px"
            >
              SIGN IN
            </Button>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          w="100%"
          mx="auto"
          mb="80px"
        />
      </Flex>
    </Flex>
  );
}

export default SignIn;
