import React from "react";

// Chakra imports
import { Flex, Grid,Text, } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GameRateRow from "components/Tables/GameRateRow";

// Data
import {
  GameRateData,
} from "variables/general";

function GameRate() {

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }} mx='auto' >
      <Grid templateColumns={{ sm: "1fr", lg: "60% 38%" }}>
        {/* Invoices List */}
        <Card
          p='22px'
          my={{ sm: "24px", lg: "0px" }}
          ms={{ sm: "0px", lg: "24px" }}>
              <Text fontSize="2xl" color="#fff" mb="18px" fontWeight="bold" textAlign="center">
                Game Win Ratio For All Bids
              </Text>
          <CardBody>
            <Flex direction='column' w='100%'>
              {GameRateData.map((row) => {
                return (
                  <GameRateRow
                    rate={row.rate}
                  />
                );
              })}
            </Flex>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
}

export default GameRate;
