import React, { useState } from 'react';
import { Box, Flex, Input, Select, Text, Grid, Button, RadioGroup, Radio, Stack, FormControl, FormLabel, color } from '@chakra-ui/react';

const SinglePattiForm = () => {
  const [selectedPoints, setSelectedPoints] = useState(null);
  const [selectedDigits, setSelectedDigits] = useState([]);
  const [session, setSession] = useState('Open');

  const handlePointClick = (point) => {
    setSelectedPoints(point);
  };

  const handleDigitToggle = (digit) => {
    setSelectedDigits((prev) =>
      prev.includes(digit)
        ? prev.filter((d) => d !== digit)
        : [...prev, digit]
    );
  };

  return (
    // <Box p={5} mt={40}>
    //   <Flex justifyContent="space-between" mb={5}>
    //     <Input type="date" width="45%" color={"white"} />
    //     <Select placeholder="KUBER DAY CL" width="45%">
    //       <option value="KUBER DAY CL">KUBER DAY CL</option>
    //     </Select>
    //   </Flex>

    //   <Text mb={3} fontWeight="bold" color="yellow.500">
    //     Select Points for Betting
    //   </Text>
    //   <Grid templateColumns="repeat(4, 1fr)" gap={2} mb={5}>
    //     {[5, 10, 20, 50, 100, 200, 500, 1000].map((point) => (
    //       <Button
    //         key={point}
    //         bg={selectedPoints === point ? 'blue.500' : 'gray.200'}
    //         color={selectedPoints === point ? 'white' : 'black'}
    //         onClick={() => handlePointClick(point)}
    //       >
    //         {point}
    //       </Button>
    //     ))}
    //   </Grid>
    //   <Text mb={3} fontWeight="bold" color="yellow.500">
    //     Select Digits
    //   </Text>
    //   <Text color={"white"} mb={2}>Select Digit 0</Text>
    //   <Grid templateColumns="repeat(4, 1fr)" gap={2} mb={5}>
    //     {[127, 132, 265, 321, 488, 590, 656, 745, 899, 912].map((digit) => (
    //       <Button
    //         key={digit}
    //         bg={selectedDigits.includes(digit) ? 'blue.500' : 'gray.200'}
    //         color={selectedDigits.includes(digit) ? 'white' : 'black'}
    //         onClick={() => handleDigitToggle(digit)}
    //       >
    //         {digit}
    //       </Button>
    //     ))}
    //   </Grid>
    //   <Text color={"white"} mb={2}>Select Digit 1</Text>
    //   <Grid templateColumns="repeat(4, 1fr)" gap={2} mb={5}>
    //     {[127, 132, 265, 321, 488, 590, 656, 745, 899, 912].map((digit) => (
    //       <Button
    //         key={digit}
    //         bg={selectedDigits.includes(digit) ? 'blue.500' : 'gray.200'}
    //         color={selectedDigits.includes(digit) ? 'white' : 'black'}
    //         onClick={() => handleDigitToggle(digit)}
    //       >
    //         {digit}
    //       </Button>
    //     ))}
    //   </Grid>
    //   <Text color={"white"} mb={2}>Select Digit 2</Text>
    //   <Grid templateColumns="repeat(4, 1fr)" gap={2} mb={5}>
    //     {[127, 132, 265, 321, 488, 590, 656, 745, 899, 912].map((digit) => (
    //       <Button
    //         key={digit}
    //         bg={selectedDigits.includes(digit) ? 'blue.500' : 'gray.200'}
    //         color={selectedDigits.includes(digit) ? 'white' : 'black'}
    //         onClick={() => handleDigitToggle(digit)}
    //       >
    //         {digit}
    //       </Button>
    //     ))}
    //   </Grid>

    //   <Flex justifyContent="space-between" mt={5}>
    //     <Button colorScheme="blue" variant="outline">
    //       Reset BID
    //     </Button>
    //     <Button colorScheme="blue">Submit BID</Button>
    //   </Flex>
    // </Box>
    <Box p={5} mt={40}>
    <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
      <FormLabel color="yellow.500">Select Date</FormLabel>
      <Input type="date" width="100%" color={"white"} />
    </FormControl>

    <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
      <FormLabel color="yellow.500">Choose Session</FormLabel>
      <RadioGroup onChange={setSession} value={session}>
        <Stack direction="row">
          <Radio value="Open" colorScheme="blue">
            <span style={{ color: "white" }}>Open</span>
          </Radio>
          <Radio value="Close" colorScheme="blue">
            <span style={{ color: "white" }}>Close</span>
          </Radio>
        </Stack>
      </RadioGroup>
    </FormControl>

    <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
      <FormLabel color="yellow.500">Bid Number</FormLabel>
      <Input type="text" placeholder="Bid Number" color="white" />
    </FormControl>

    <FormControl width={{ base: '100%', md: '50%' }} mb={5}>
      <FormLabel color="yellow.500">Bid Points</FormLabel>
      <Input type="number" placeholder="Bid Points" color="white" />
    </FormControl>

    <Flex justifyContent="space-between" mt={5}>
      <Button colorScheme="blue">Submit BID</Button>
    </Flex>
  </Box>
  );
};

export default SinglePattiForm;
