import React from "react";
import { Button, Input, FormControl, FormLabel, Flex, Select } from "@chakra-ui/react";

function WithdrawForm() {
  return (
    <Flex direction="column" gap="40px">
      <FormControl>
        <FormLabel>Amount</FormLabel>
        <Input placeholder="Enter amount to withdraw" type="number" />
      </FormControl>

      {/* Payment Method Section */}
      <FormControl>
        <FormLabel>Select Payment Method</FormLabel>
        <Select placeholder="Select payment method">
          <option value="bankTransfer">Bank Transfer</option>
          <option value="paypal">PayPal</option>
          <option value="crypto">Cryptocurrency</option>
        </Select>
      </FormControl>

      <Button colorScheme="green" marginBottom="40px">
        Withdraw
      </Button>
    </Flex>
  );
}

export default WithdrawForm;
