// import
import Dashboard from "views/Dashboard/Dashboard.js";
import BidHistory from "views/Dashboard/BidHistory.js";
import Billing from "views/Dashboard/Billing.js";
import Games from "views/Dashboard/Games.js";
import GameRate from "views/Dashboard/GameRate.js";
import ManageFund from "views/Dashboard/ManageFund.js";
import SingalAnkForm from "views/Dashboard/SingalAnkForm.js";
import SinglePattiForm from "views/Dashboard/SinglePattiForm.js";
import DoublePattiForm from "views/Dashboard/DoublePattiForm.js";
import TripplePattiForm from "views/Dashboard/TripplePattiForm.js";
import SpMotor from "views/Dashboard/SpMotor.js";
import AllPatti from "views/Dashboard/AllPatti.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import ForgotPassword from "views/Pages/ForgotPassword.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
  LogoutIcon,
  ForgotPasswordIcon,
} from "components/Icons/Icons";
import HowToPlay from "views/Dashboard/HowToPlay";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    
    icon: <HomeIcon color='inherit' />,
    component: Dashboard,
    layout: "/home",
     showInSidebar: true,
  },
  {
    path: "/bidHistory",
    name: "Bids History",
    
    icon: <StatsIcon color='inherit' />,
    component: BidHistory,
    layout: "/home",
     showInSidebar: true,
  },
  {
    path: "/gameRate",
    name: "Game Rate",
    
    icon: <CreditIcon color='inherit' />,
    component: GameRate,
    layout: "/home",
     showInSidebar: true,
  },
  {
    path: "/howtoplay",
    name: "How To Play",
    
    icon: <CreditIcon color='inherit' />,
    component: HowToPlay,
    layout: "/home",
     showInSidebar: true,
  },
  {
    path: "/manageFund",
    name: "Manage Fund",
    
    icon: <CreditIcon color='inherit' />,
    component: ManageFund,
    layout: "/home",
     showInSidebar: true,
  },
  {
    path: "/billing",
    name: "Billing",
    
    icon: <CreditIcon color='inherit' />,
    component: Billing,
    layout: "/home",
    showInSidebar: true,
  },
  {
    path: "/games",
    name: "Games",
    component: Games,
    layout: "/home",
    showInSidebar: false,
  },
  {
    path: "/singal-ank",
    name: "Single Ank",
    component: SingalAnkForm,
    layout: "/home",
    showInSidebar: false,
  },
  {
    path: "/singal-patti",
    name: "Single Patti",
    component: SinglePattiForm,
    layout: "/home",
    showInSidebar: false,
  },
  {
    path: "/double-patti",
    name: "Double Patti",
    component: DoublePattiForm,
    layout: "/home",
    showInSidebar: false,
  },
  {
    path: "/tripple-patti",
    name: "Tripple Patti",
    component: TripplePattiForm,
    layout: "/home",
    showInSidebar: false,
  },
  {
    path: "/half-sangam",
    name: "Half Sangam",
    component: AllPatti,
    layout: "/home",
    showInSidebar: false,
  },
  {
    path: "/full-sangam",
    name: "Full Sangam",
    component: SpMotor,
    layout: "/home",
    showInSidebar: false,
  },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   icon: <SupportIcon color='inherit' />,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  {
    name: "ACCOUNT",
    category: "account",
    state: "pageCollapse",
    views: [
      // {
      //   path: "/profile",
      //   name: "Profile",
        
      //   icon: <PersonIcon color='inherit' />,
      //   secondaryNavbar: true,
      //   component: Profile,
      //   layout: "/home",
      // },
      {
        path: "/signin",
        name: "Sign In",
        
        icon: <DocumentIcon color='inherit' />,
        component: SignIn,
        layout: "/auth",
     showInSidebar: true,
      },
      {
        path: "/signup",
        name: "Sign Up",
        
        icon: <RocketIcon color='inherit' />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
     showInSidebar: true,
      },
      {
        // path: "/signup",
        name: "Logout",
        
        icon: <LogoutIcon color='inherit' />,
        secondaryNavbar: true,
        // component: SignUp,
        layout: "/auth",
     showInSidebar: true,
      },
      {
      path: "/forgot-password",
      name: "Change Password",
      icon: <ForgotPasswordIcon color='inherit' />,
      component: ForgotPassword,
      layout: "/auth",
     showInSidebar: true,
    },
    ],
  },
];
export default dashRoutes;
