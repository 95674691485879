import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import React from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import AddFundForm from "components/Model/AddFundForm";
import WithdrawForm from "components/Model/WithdrawForm";
import AddBankDetailsForm from "components/Model/AddBankDetailsForm";
import FundHistory from "components/Model/FundHistory";
import WithdrawHistory from "components/Model/WithdrawHistory";

function ManageFundRow(props) {
  const { name, discription, type } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderModalContent = () => {
    switch (type) {
      case "addFund":
        return <AddFundForm />;  // Render the Add Fund form
      case "withdraw":
        return <WithdrawForm />;  // Render the Withdraw form
      case "addBankDetails":
        return <AddBankDetailsForm />;  // Render the Add Bank Details form
      case "fundHistory":
        return <FundHistory />;  // Render the Fund History content
      case "withdrawHistory":
        return <WithdrawHistory />;  // Render the Withdraw History content
      default:
        return <Text>No action available.</Text>;
    }
  };

  return (
    <Box
      p="24px"
      bg="linear-gradient(127.09deg, rgba(24, 29, 60, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)"
      my="22px"
      borderRadius="20px"
    >
      <Flex justify="space-between" w="100%" align="flex-start">
        <Flex direction="column" maxW="70%">
          <Text color="#fff" fontWeight="bold" fontSize="m" mb="10px">
            {name}
          </Text>
          <Text color="gray.400" fontSize="s">
            {discription}
          </Text>
        </Flex>
        <Flex direction={{ sm: "column", md: "row" }} align="flex-start">
          <Button p="0px" variant="no-hover" onClick={onOpen}>
            <Flex color="#fff" cursor="pointer" align="center" p="12px">
              <Icon as={ChevronRightIcon} me="4px" w="60px" h="40px" />
            </Flex>
          </Button>
        </Flex>
      </Flex>

      {/* Modal */}
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderModalContent()}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ManageFundRow;
