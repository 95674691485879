import React from "react";

// Chakra imports
import { Flex, Grid, Text, Box, AspectRatio } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

function HowToPlay() {
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }} mx="auto">
      <Grid templateColumns={{ sm: "1fr", lg: "60% 38%" }}>
        {/* How To Play Section */}
        <Card p="22px" my={{ sm: "24px", lg: "0px" }} ms={{ sm: "0px", lg: "24px" }}>
          <Text fontSize="2xl" color="#fff" mb="18px" fontWeight="bold" textAlign="center">
            How to Play
          </Text>
          <CardBody>
            <Flex direction="column" w="100%">
              <Box mb="16px">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="8px">
                  Step 1: Select the Game
                </Text>
                <Text color="#fff">
                  Start by choosing the game you want to play. There are various games available, each with different rules and mechanics.
                </Text>
              </Box>
              <Box mb="16px">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="8px">
                  Step 2: Place Your Bid
                </Text>
                <Text color="#fff">
                  Once you've selected a game, place your bid by selecting the points you wish to wager. Make sure to choose wisely based on your strategy.
                </Text>
              </Box>
              <Box mb="16px">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="8px">
                  Step 3: Choose Your Numbers
                </Text>
                <Text color="#fff">
                  Select the numbers you believe will win. You can pick from a range of available digits depending on the game format.
                </Text>
              </Box>
              <Box mb="16px">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="8px">
                  Step 4: Submit Your Bid
                </Text>
                <Text color="#fff">
                  After making your selections, submit your bid. You'll be locked in once you confirm, and no further changes can be made.
                </Text>
              </Box>
              <Box mb="16px">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="8px">
                  Step 5: Wait for Results
                </Text>
                <Text color="#fff">
                  Once all players have submitted their bids, the results will be calculated. If your chosen number matches the winning number, you'll win the prize.
                </Text>
              </Box>

              {/* YouTube Video */}
              <Box mt="24px">
                <Text fontSize="lg" color="#fff" fontWeight="bold" mb="8px">
                  Watch the Tutorial
                </Text>
                <AspectRatio ratio={16 / 9}>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/sT0n5zOF2bI?si=2LCHNsfRnjpfJDA-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </AspectRatio>
              </Box>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
}

export default HowToPlay;
