import React, { useState } from 'react';
import { Box, Flex, Input, Select, Text, Grid, Button, RadioGroup, Radio, Stack, FormControl, FormLabel, color } from '@chakra-ui/react';

const BettingForm = () => {
  const [session, setSession] = useState('Open');

  return (
    <Box p={5} mt={40}>
      {/* Date Field */}
      <FormControl mb={5}>
        <FormLabel color="yellow.500">Select Date</FormLabel>
        <Input type="date" width={{ base: '100%', md: '50%' }} color="white" />
      </FormControl>

      {/* Close Digit Field */}
      <FormControl mb={5}>
        <FormLabel color="yellow.500">Close Digit</FormLabel>
        <Input type="text" placeholder="Close Digit" color="white" width={{ base: '100%', md: '50%' }} />
      </FormControl>

      {/* Open Panna Field */}
      <FormControl mb={5}>
        <FormLabel color="yellow.500">Open Panna</FormLabel>
        <Input type="text" placeholder="Open Panna" color="white" width={{ base: '100%', md: '50%' }} />
      </FormControl>

      {/* Bid Points Field */}
      <FormControl mb={5}>
        <FormLabel color="yellow.500">Bid Points</FormLabel>
        <Input type="number" placeholder="Bid Points" color="white" width={{ base: '100%', md: '50%' }} />
      </FormControl>

      {/* Submit Button */}
      <Flex justifyContent="space-between" mt={5}>
        <Button colorScheme="blue">Submit BID</Button>
      </Flex>
    </Box>
  );
}

export default BettingForm;
