import React from "react";
import { Button, Input, FormControl, FormLabel, Flex } from "@chakra-ui/react";

function AddBankDetailsForm() {
  return (
    <Flex direction="column" gap="60px">
      <FormControl>
        <FormLabel>Account Holder Name</FormLabel>
        <Input placeholder="Enter your account holder name" />
      </FormControl>
      <FormControl>
        <FormLabel>Bank Name</FormLabel>
        <Input placeholder="Enter your bank name" />
      </FormControl>
      <FormControl>
        <FormLabel>Account Number</FormLabel>
        <Input placeholder="Enter your account number" type="number" />
      </FormControl>
      <FormControl>
        <FormLabel>IFSC Number</FormLabel>
        <Input placeholder="Enter your IFSC number" type="number" />
      </FormControl>
      <Button colorScheme="purple" marginBottom={'40px'}>Save Bank Details</Button>
    </Flex>
  );
}

export default AddBankDetailsForm;
